import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home/HomePage";
import About from "./components/pages/About/AboutPage";
import Contact from "./components/pages/Contact/ContactPage";
import Demo from "./components/pages/Demo/DemoPage";

import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/About" component={About} />
                    <Route path="/ContactUs" component={Contact} />
                    <Route path="/Demo" component={Demo} />
                </Switch>
            </Router>
        )
    }
}