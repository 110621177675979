import React, { Component } from 'react'
import kerithProfile from './profile_img/circle-kd.png'
import HaesunProfile from './profile_img/circle-hs.png'
import JuliaProfile from './profile_img/circle-js.png'
import JackieProfile from './profile_img/circle-jw.png'
import oscar from './profile_img/oscar.JPG'
import sam from './profile_img/sam.JPG'


const AboutPage = () => {
    return (
        <div class="container" style={{ margin: 'auto', width: '100%'}}>
            <h1 className='Who'>Our Team <span style={{ fontWeight: 'bold', color:'#48B7AA' }}>Rare</span>Genic<span style={{ fontStyle: 'italic' }}> !</span></h1>
            <div className='desktop-profile'>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={kerithProfile} className="Profile" />
                    <div  className='profile-subbox'>
                        <div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Kerith-Rae Dias</div>
                        <div className='profile-position'>Founder & Clinical Genomics Researcher</div>
                        <div className='profile-explanation'>
                            Kerith-Rae’s passion is translating genomics into healthcare. She asked the question “why?” and has bought the idea of RareGenic and the incredible evolving world of genomics to the team, creating equal parts enthusiasm and wonder at the potential of RareGenic to make a difference and help people. She is a high achiever with an impressive career and academic transcript, including a PhD in progress.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/kerith-rae-dias-02806041/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> k.dias@raregenic.com</div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={JuliaProfile} className="Profile" />
                    <div  className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Julia Smith</div>
                        <div className='profile-position'>Founder & Business Strategist</div>
                        <div className='profile-explanation'>
                            Julia complements the team with her business strategy and organizational skills, working to bring the elements together in a narrative of ambitious realism for RareGenic. Driven to make a difference to businesses and people in business, since completing her MBA, Julia has been excited to ‘do different things’, explore and enjoy creating new partnerships and collaborations.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/julia-smith-91046816/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> julia.smith@raregenic.com</div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={JackieProfile} className="Profile" />
                    <div  className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Jacqueline C. Williams</div>
                        <div className='profile-position'>Founder & Digital, Technology Strategist</div>
                        <div className='profile-explanation'>
                             With a career long zeal and aptitude for technology, an MBA (Technology) under her belt and access to some really cool and useful tech tools, Jackie is one of the team’s tech sisters. Jackie is courageous, curious and had a blast contributing to the RareGenic’s business model, even using an old corporate finance spreadsheet as part of the crazy ride over the last two weeks.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/jacqueline-chapman-williams/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> j.c.williams@raregenic.com</div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={HaesunProfile} className="Profile" />
                    <div  className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Haesun Shim</div>
                        <div className='profile-position'>Founder & Full Stack Developer, UI·UX Designer</div>
                        <div className='profile-explanation'>
                            One of the team’s tech sisters, showing her skill by whipping up the RareGenic prototype and website over a weekend. Haesun reaches out to find and fully embraces every opportunity she has access to. She’s motivated to explore and develop how technology can make people’s lives better and throws herself into her pursuits with excellence and commitment.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/haesun-shim-948642193/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> haesun.shim@raregenic.com</div>
                    </div>
                </div>
            </div>
            <div className='desktop-cat-profile'>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={oscar} className="Profile2" />
                    <div className='profile-subbox'>
                        <div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Oscar</div>
                        <div className='profile-explanation'>
                           (Picture of procrastinating Oscar.) Since COVID-19 Oscar has become a national and international video call star, amusing people with his persistence for attention, his insistent voice and his love of lounging, just hanging over his mother’s left shoulder. He has been an active participant in the RareGenic project and contributed to many points of development..
                        </div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={sam} className="Profile2" />
                    <div className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Sam</div>
                        <div className='profile-explanation'>Sam was an early participant in the RareGenic project. An excellent listener, liking quietly snuggling on a lap, making less contributions than his brother but making them count. Sadly, his health deteriorated during the project and he crossed the rainbow bridge before the pitch was developed. RIP Sammy.
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Responsive  */}
            {/* <h1 className='Who'>Our Team <span style={{ fontWeight: 'bold', color:'#48B7AA' }}>Rare</span>Genic<span style={{ fontStyle: 'italic' }}> !</span></h1> */}
            <div className='mobile-profile' >
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={kerithProfile} className="Profile" />
                    <div className='profile-subbox'>
                        <div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Kerith-Rae Dias</div>
                        <div className='profile-position'>Founder & Clinical Genomics Researcher</div>
                        <div className='profile-explanation'>
                            Kerith-Rae’s passion is translating genomics into healthcare. She asked the question “why?” and has bought the idea of RareGenic and the incredible evolving world of genomics to the team, creating equal parts enthusiasm and wonder at the potential of RareGenic to make a difference and help people. She is a high achiever with an impressive career and academic transcript, including a PhD in progress.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/kerith-rae-dias-02806041/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> k.dias@raregenic.com</div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={JuliaProfile} className="Profile" />
                    <div className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Julia Smith</div>
                        <div className='profile-position'>Founder & Business Strategist</div>
                        <div className='profile-explanation'>
                            Julia complements the team with her business strategy and organizational skills, working to bring the elements together in a narrative of ambitious realism for RareGenic. Driven to make a difference to businesses and people in business, since completing her MBA, Julia has been excited to ‘do different things’, explore and enjoy creating new partnerships and collaborations.
                        </div>
                    </div>
                    <div className='personal-contact'>
                    <a href='https://www.linkedin.com/in/julia-smith-91046816/'>
                        <div className='linkedin-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                            </svg>
                        </div>
                    </a>
                    <div className='personal-email'> julia.smith@raregenic.com</div>
                </div>
                </div>
            </div>
            <div className='mobile-profile' >
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={JackieProfile} className="Profile" />
                    <div className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Jacqueline C. Williams</div>
                        <div className='profile-position'>Founder & Digital, Technology Strategist</div>
                        <div className='profile-explanation'>
                            With a career long zeal and aptitude for technology, an MBA (Technology) under her belt and access to some really cool and useful tech tools, Jackie is one of the team’s tech sisters. Jackie is courageous, curious and had a blast contributing to the RareGenic’s business model, even using an old corporate finance spreadsheet as part of the crazy ride over the last two weeks.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/jacqueline-chapman-williams/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> j.c.williams@raregenic.com</div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={HaesunProfile} className="Profile" />
                    <div className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Haesun Shim</div>
                        <div className='profile-position'>Founder & Full Stack Developer, UI·UX Designer</div>
                        <div className='profile-explanation'>
                            One of the team’s tech sisters, showing her skill by whipping up the RareGenic prototype and website over a weekend. Haesun reaches out to find and fully embraces every opportunity she has access to. She’s motivated to explore and develop how technology can make people’s lives better and throws herself into her pursuits with excellence and commitment.
                        </div>
                    </div>
                    <div className='personal-contact'>
                        <a href='https://www.linkedin.com/in/haesun-shim-948642193/'>
                            <div className='linkedin-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </div>
                        </a>
                        <div className='personal-email'> haesun.shim@raregenic.com</div>
                    </div>
                </div>
            </div>
            <div className='mobile-cat-profile'>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={oscar} className="Profile2" />
                    <div className='profile-subbox'>
                        <div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Oscar</div>
                        <div className='profile-explanation'>
                           (Picture of procrastinating Oscar.) Since COVID-19 Oscar has become a national and international video call star, amusing people with his persistence for attention, his insistent voice and his love of lounging, just hanging over his mother’s left shoulder. He has been an active participant in the RareGenic project and contributed to many points of development..
                        </div>
                    </div>
                </div>
                <div className='profile-box' style={{ float: 'left' }}>
                    <img src={sam} className="Profile2" />
                    <div className='profile-subbox'><div className='profile-name' style={{ marginBottom: '10px', fontWeight: 'bold' }}>Sam</div>
                        <div className='profile-explanation'>Sam was an early participant in the RareGenic project. An excellent listener, liking quietly snuggling on a lap, making less contributions than his brother but making them count. Sadly, his health deteriorated during the project and he crossed the rainbow bridge before the pitch was developed. RIP Sammy.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage
