import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import slider1 from './img/family-text2.png'
import slider2 from './img/light-text2.png'
import founders from './img/founders_logo_black.png'

const Carousel = () => {
    return (
        <div>
            <AliceCarousel autoPlay autoPlayInterval="3000">
                <img src={slider2} className="slider-img"/>
                <img src={slider1} className="slider-img"/>
            </AliceCarousel>
            <h1 className="partners">Our Partners</h1>
            <img src={founders} className="UNSWpartner" />
            <br /><br /><br />
            <p style={{textAlign: 'center', marginBottom: '3%'}}>© Copyright RareGenic. All Rights Reserved</p>
        </div>
    )
}

export default Carousel