import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const images = [
  {
    title: 'Click here to follow our journey on Linkedin',
    width: '40%',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.blue,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.black,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function ContactPage() {
    const classes = useStyles();
  return (
      <div>
            <h1 style={{ textAlign: 'center', fontSize: '18pt', marginTop: '50px'}}>GET IN TOUCH</h1>
            <p style={{ textAlign: 'center', fontSize: '16pt',marginTop: '50px' }}>Contact the RareGenic team to find out more about our work</p>
        <div className='emaillink' style={{ textAlign: 'center', fontSize: '16pt', marginTop: '50px' }}><a href="mailto:info@raregenic.com">info@raregenic.com</a>  </div>
     
       
        <div className={classes.root} style={{margin:'auto',width:'100%'}}>
            {images.map((image) => (
                <ButtonBase
                focusRipple
                key={image.title}
                className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                style={{
                    width: '100%',
                }}
                >
                <span
                    className={classes.imageSrc}
                    style={{
                    background: 'white',
                    }}
                />
                    <span className={classes.imageBackdrop} />
                    <a href="https://www.linkedin.com/company/68956735/">
                        <span className={classes.imageButton}>
                            <Typography
                            component="span"
                            variant="subtitle1"
                            // color="inherit"
                            color='rgb(76, 193, 211)'
                            className={classes.imageTitle}
                            >
                            {image.title}
                            <span className={classes.imageMarked} />
                            </Typography>
                        </span>
                    </a>
                </ButtonBase>
            ))}
        </div>
    </div>
  );
}
