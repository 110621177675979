import React, { Component } from 'react'
import { MenuItems } from "./MenuItems" 
import { Button } from "../Button"
import './Navbar.css'

class Navbar extends Component {
    state = {clicked: false }

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }

    render() {
        return (
            <nav className="NavbarItems">
                <h1 style={{ color: '#4cc1d3' }} className="navbar-logo">
                    {/* <a href="https://www.linkedin.com/company/68956735"> */}
                        Rare<span style={{ color: '#ffff' }}>Genic</span>
                    {/* </a> */}
                </h1>

                <div className="menu-icon" onClick={this.handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="white" class="bi bi-list" viewBox="0 0 26 26">
                        <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                    {/* <i className={this.state.clicked ? 'fase fa-times' : 'fas fa-bars'}></i> */}
                </div>
              
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                    {item.title}
                                </a>
                            </li>

                        )
                    })}
                </ul>
                <Button
                    type="button"
                    onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/Demo';
                    }}
                >Demo</Button>
            </nav>
        )
    }
}

export default Navbar