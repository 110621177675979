
export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '/About',
        cName: 'nav-links'
    },
    {
        title: 'Contact Us',
        url: '/ContactUs',
        cName: 'nav-links'
    },
    {
        title: 'Demo',
        url: '/Demo',
        cName: 'nav-links-mobile'
    },
]