import React, { Component } from 'react'
import FigmaEmbed from 'react-figma-embed';
const DemoPage = () => {
    return (
        <div>
            <FigmaEmbed url='https://www.figma.com/proto/QWfiHQwBKVfXBAENMN0LAi/RareGenic-Second-prototype?node-id=1%3A2&scaling=min-zoom' width="100%" height="900"/>
    {/* <FigmaEmbed url='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQWfiHQwBKVfXBAENMN0LAi%2FRareGenic-Second-prototype%3Fnode-id%3D71%253A2608'/> */}
        {/* <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQWfiHQwBKVfXBAENMN0LAi%2FRareGenic-Second-prototype%3Fnode-id%3D71%253A2608" allowfullscreen></iframe> */}

        </div>

    )
}

export default DemoPage
